import React from 'react';

const TestModule: React.FC = () => {
	return (
		<>
			Test modul
			</>
	);
}

export default TestModule