import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
	Store
} from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import { History, createBrowserHistory } from 'history';
import IAppState from './IAppState';

// Reducers
import { DokumentReducer } from './dokument/DokumentReducer';

export const history = createBrowserHistory();
export default function configureStore(pInitialState: IAppState): Store<IAppState>  {
	// ---REDUCERS---
	const rootReducer = combineReducers<IAppState>({
		router: connectRouter(history),
		Dokument: DokumentReducer
	});

	// ---MIDDLEWARES---
	const middleware = [
		routerMiddleware(history)		
	];

		// ---ENHANCERS---
	const composeEnhancers = composeWithDevTools({});

	// ---CREATE STORE---
	const store = createStore(
		rootReducer,
		pInitialState,
		composeEnhancers(
			compose(applyMiddleware(...middleware))
		)
	);

	return store;
}