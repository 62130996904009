import React from 'react';
import moment from 'moment';

import { makeStyles, createStyles, Theme, IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { DeleteOutlined } from '@material-ui/icons';

import ZakaznikDokumenty from '../../../models/application/zakaznik/ZakaznikDokument';

interface IProps {
    ZakaznikDokumentyData: Array<ZakaznikDokumenty>;
    onClickDelete: (e: any, IdDocEntityLink: number) => void;
    NazevText: string;
    DatumText: string;
    PoznamkaText: string;
    AkceText: string;
}

const DokumentyTable: React.FC<IProps> = (props) => {
    const classes = useStyles();

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {props.NazevText}
                        </TableCell>
                        <TableCell>
                            {props.DatumText}
                        </TableCell>
                        <TableCell>
                            {props.PoznamkaText}
                        </TableCell>
                        <TableCell>
                            {props.AkceText}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.ZakaznikDokumentyData.map((val, index) => {                        
                        return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>
                                <TableCell>
                                    {val.Name}
                                </TableCell>
                                <TableCell>
                                    {moment(val.Changed).format("DD.MM.YYYY HH:mm:ss")}
                                </TableCell>
                                <TableCell>
                                    {val.Description}
                                </TableCell>

                                <TableCell>
                                    <IconButton arial-label="Delete" onClick={(e) => props.onClickDelete(e, val.IdDocEntityLink || 0)}>
                                        <DeleteOutlined fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    })
);

export default DokumentyTable;