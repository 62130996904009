import React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button, Drawer, Typography, AppBar, CssBaseline, Toolbar, List, IconButton,
    ListItem, ListItemIcon, ListItemText, Menu, MenuItem
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import logo from '../zfp-logo-white.png';

import { LanguageCodeEnum } from '../models/enums/LanguageCodeEnum';
interface IProps {

}

const Layout: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOnCloseJazykMenu = () => {
        setAnchorEl(null);
    };

    const menuJazykOnClick = (e: any, pJazyk: LanguageCodeEnum) => {
        i18n.changeLanguage(pJazyk.toString().toLowerCase());
        setAnchorEl(null);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        ZFP Group
                    </Typography>

                    <div style={{ marginLeft: 'auto' }}>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            Jazyk - {i18n.language}
                    </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleOnCloseJazykMenu}
                        >
                            <MenuItem onClick={(e) => menuJazykOnClick(e, LanguageCodeEnum.CZ)}>CZ</MenuItem>
                            <MenuItem onClick={(e) => menuJazykOnClick(e, LanguageCodeEnum.SK)}>SK</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <div style={{ marginLeft: '70px' }}>
                        <img src={logo} className="" alt="logo" />
                    </div>
                    <IconButton onClick={handleDrawerClose} style={{ marginLeft: 'auto' }}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                
                <List>
                    <ListItem button >
                        <ListItemIcon><InsertDriveFileOutlinedIcon style={{ color: '#fff' }} /></ListItemIcon>
                        <ListItemText primary={"Dokumenty"} />
                    </ListItem>

                    {/*<ListItem button >
                        <ListItemIcon><InsertDriveFileOutlinedIcon style={{ color: '#fff' }} /></ListItemIcon>
                        <ListItemText primary={"Test"} />
                    </ListItem>*/}
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {props.children}
            </main>
        </div>
    );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#fff',
            color: 'black'
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'center'
        },
        drawerHeaderShadow: {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }),
);

export default Layout;
