import { Reducer } from 'redux';

import IDokumentState from './IDokumentState';
import { DokumentAction, DokumentActionTypes } from './DokumentActions';

const initialState: IDokumentState = {
	
}

const reducer: Reducer<IDokumentState, DokumentAction> = (state = initialState, action) => {
	switch (action.type) {

		default: {
			return state
		}
	}
}

export { reducer as DokumentReducer }