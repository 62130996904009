import React from 'react';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';

import DokumentyUploadPage from './../pages/DokumentyUploadPage';


const DokumentModule: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<DokumentyUploadPage />
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		}		
	}),
);

export default DokumentModule