import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import configureStore, { history } from './store/ConfigureStore';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import AppTheme from './AppTheme';

import './i18n';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';


// Store
const initialState = (window as any).initialReduxState;
const store = configureStore(initialState);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ThemeProvider theme={AppTheme}>
				<CssBaseline />
				<Suspense fallback="Loading...">
					<App />
				</Suspense>
			</ThemeProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
