import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { makeStyles, useTheme, Theme, createStyles, Typography, Grid } from '@material-ui/core';

import FileUpload from './../common/FileUpload';
import DokumentyTable from './../common/dokument/DokumentyTable';

import DokumentRepository from '../../repositories/DokumentRepository';
import OsobaRepository from '../../repositories/OsobaRepository';

import ZakaznikDokumenty from '../../models/application/zakaznik/ZakaznikDokument';
import Osoba from '../../models/entities/osoba/osoba';
import FileInfo from '../../models/helpers/FileInfo';

import DokumentyPoznamkaDialog from './../common/dokument/DokumentyPoznamkaDialog';
import AppAlert from '../common/shared/AppAlert';

import { useTranslation } from 'react-i18next';
import DokumentyKlientaUrl from '../../models/application/dokumenty/DokumentyKlientaUrl';
import AppAlertProps from '../../models/helpers/AppAlertProps';
import { LanguageCodeEnum } from '../../models/enums/LanguageCodeEnum';


interface IUrlParams {
	docToken: string;
}

const DokumentyUploadPage: React.FC = () => {

	const getUrlParams = (pParams: any): IUrlParams => {
		const { docToken } = pParams;

		const result: IUrlParams = {
			docToken: docToken || ""
		}

		return result;
	}

	const dokumentRepo = new DokumentRepository();
	const osobaRepo = new OsobaRepository();
	const classes = useStyles();
	const urlParams: IUrlParams = getUrlParams(useParams());
	const { t, i18n } = useTranslation(['dokumentyuploadpage']);

	// STATE
	const [Open, setOpen] = useState<boolean>(false);
	const [UrlValid, setUrlValid] = useState<boolean>(false);
	const [OverovaniUrl, setOverovaniUrl] = useState<boolean>(true);
	const [ZakaznikDokumentyData, setZakaznikDokumentyData] = useState<Array<ZakaznikDokumenty>>([]);
	const [OsobaInfo, setOsobaInfo] = useState<Osoba>(new Osoba());
	const [poznamkaDialogOpen, setPoznamkaDialogOpen] = useState<boolean>(false);
	const [files, setFiles] = useState<Array<any>>([]);
	const [fileInfo, setFileInfo] = useState<Array<FileInfo>>([]);
	const [urlIfno, setUrlInfo] = useState<DokumentyKlientaUrl>(new DokumentyKlientaUrl());
	const [appAlertProps, setAppAlertProps] = useState<AppAlertProps>(new AppAlertProps());


	useEffect(() => {
		getZdaUrlValid()
	}, [UrlValid]);

	useEffect(() => {
		getDokumentyKlienta();
	}, []);

	useEffect(() => {
		getOsobaInfo();
	}, []);

	useEffect(() => {
		getUrlInfo();
	}, []);

	// GET
	const getZdaUrlValid = async (): Promise<boolean> => {
		const valid: boolean = await dokumentRepo.overeniPlatnostiUrl(urlParams.docToken);
		setUrlValid(valid);

		if (OverovaniUrl)
			setOverovaniUrl(false);		

		return valid;
	}	

	const getDokumentyKlienta = async () => {
		if (!await getZdaUrlValid())
			return;		

		const result: Array<ZakaznikDokumenty> = await dokumentRepo.getDokumentyZakanzika(urlParams.docToken) || [];
		setZakaznikDokumentyData(result);
	}

	const getOsobaInfo = async () => {		
		if (!await getZdaUrlValid())
			return;

		const result: Osoba = await osobaRepo.getByIdZakaznik(null, urlParams.docToken) || new Osoba();
		setOsobaInfo(result);
	}

	const getUrlInfo = async () => {
		if (!await getZdaUrlValid())
			return;

		const result: DokumentyKlientaUrl = await dokumentRepo.getUrlInfo(urlParams.docToken) || new DokumentyKlientaUrl();
		i18n.changeLanguage(result.ZemeOz.toString().toLowerCase() || LanguageCodeEnum.CZ);
		setUrlInfo(result);
	}

	const getFileInfo = (pFiles: Array<any>): Array<FileInfo> => {
		let result: Array<FileInfo> = [];

		if (pFiles.length > 0) {
			result = pFiles.map((val, index) => {
				return new FileInfo({ FileName: val.name })
			});
		}

		return result;
	}


	// HANDLE
	const handleSave = async (files: any) => {
		if (!await getZdaUrlValid())
			return;

		setFiles(files);
		setFileInfo(getFileInfo(files));
		setPoznamkaDialogOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	const handleOpen = () => {
		setOpen(true);
	}

	const handleOnClickDelete = async (e: any, IdDocEntityLink: number) => {
		if (!await getZdaUrlValid())
			return;

		await dokumentRepo.delete(urlParams.docToken, IdDocEntityLink);
		setAppAlertProps(new AppAlertProps({ Open: true, Message: "Úspěšně smazáno", Severity: "success" }));
		getDokumentyKlienta();
	}

	const handleOnCloseDialog = async () => {
		if (!await getZdaUrlValid())
			return;
		
		setPoznamkaDialogOpen(false);
		setOpen(false);

		await dokumentRepo.upload(urlParams.docToken, files, fileInfo);

		getDokumentyKlienta();
	}

	const onClickZrusitButton = () => {
		setPoznamkaDialogOpen(false);
	}

	const handleOnChangePoznamkaDialog = (e: any, index: number) => {
		const value = e.target.value;

		let newFileInfo = fileInfo.map((val, index) => {
			return new FileInfo({ FileName: val.FileName, Poznamka: val.Poznamka });
		});
		newFileInfo[index].Poznamka = value;

		setFileInfo(newFileInfo);
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{appAlertProps.Open &&
						<AppAlert
							AppAlertProps={appAlertProps}
							SetAppAlertProps={setAppAlertProps}
						/>
					}
					<Typography variant="h6">
						Dokumenty {UrlValid ? OsobaInfo.OsobaFullName : ""}
					</Typography>
				</Grid>
				{UrlValid ?
					(
						<>
							<Grid item xs={12}>
								{t('PlatnostOdkazuInfo')}: {moment(urlIfno.PlatnostDo).format("DD.MM.YYYY HH:mm:ss")}
							</Grid>
							<Grid className={classes.addFlex} item xs={12} justify="flex-end">
								<FileUpload
									Open={Open}
									DialogOnSave={handleSave}
									DialogOnClose={handleClose}
									ButtonOnClick={handleOpen}
									PridatButtonText={t('DialogPridatButtonText')}
									CancelButtonText={t('Sdilene.Zrusit')}
									SubmitButtonText={t('Sdilene.Ulozit')}
									DialogTitle={t('DialogTitle')}
									DialogText={t('DialogText')}
								/>
							</Grid>
							<Grid item xs={12} style={{ wordBreak: "break-word" }}>
								<DokumentyTable
									ZakaznikDokumentyData={ZakaznikDokumentyData}
									onClickDelete={handleOnClickDelete}
									AkceText={t('DokumentyTable.Akce')}
									DatumText={t('DokumentyTable.Datum')}
									NazevText={t('DokumentyTable.Nazev')}
									PoznamkaText={t('DokumentyTable.Poznamka')}
								/>
							</Grid>

							<Grid xs={12}>
								<DokumentyPoznamkaDialog
									open={poznamkaDialogOpen}
									fileInfo={fileInfo}
									onClickCloseDialog={handleOnCloseDialog}
									onClickZrusitDialogButton={onClickZrusitButton}
									onClickEditDialogButton={handleOnCloseDialog}
									onClickSaveDialogButton={handleOnCloseDialog}
									onChangePoznamkaDialog={handleOnChangePoznamkaDialog}
									dialogTitle={t('DokumentyPoznamkaDialog.UploadDialogTitle')}
									dialogContentText={t('DokumentyPoznamkaDialog.UploadDialogPoznamkaContentText')}
									dialogButtonAnoText={t('Sdilene.Ano')}
									dialogButtonUlozitText={t('Sdilene.Ulozit')}
									dialogButtonZrusitText={t('Sdilene.Zrusit')}
									dialogButtonPouzeUlozitText={t('DokumentyPoznamkaDialog.PouzeUlozit')}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={12} justify="center">
							{OverovaniUrl ? (
								<>
									<Typography variant="body2" align="center">
										{t('OverovaniPlatnostiOdkazuMessage')}
									</Typography>
								</>
							) : (<>
									<Typography variant="body2" align="center" color="error">
										{t('PlatnostOdkazuMessage')}
									</Typography>
								</>)}							
						</Grid>
					)
				}
			</Grid>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
		},
		addFlex: {
			display: 'flex'
		}
	}),
);

export default DokumentyUploadPage;