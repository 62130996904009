import axios, { AxiosResponse } from 'axios';

import Osoba from '../models/entities/osoba/osoba';

export default class OsobaRepository {
	private url: string;

	constructor() {
		this.url = process.env.REACT_APP_API_URL || "";
	}

	public async getByIdZakaznik(pIdZakaznik: number | null, pToken: string) {
		const url = `${this.url}/osoba/getbyidzakaznik?pIdZakaznik=${pIdZakaznik}&pToken=${pToken}`;

		return await new Promise<Osoba>((resolve) => {
			axios.get(url)
				.then((response: AxiosResponse<Osoba>) => {
					resolve(response.data);
				})
				.catch(error => {
					// log error
				})
		})
	}
}