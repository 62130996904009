import React from 'react';
import logo from './logo.svg';
import './App.css';

import Layout from './components/Layout';
import { Route, Switch } from 'react-router';

// Modules
import DokumentModule from './components/modules/DokumentModule';
import TestModule from './components/modules/TestModule';

const App: React.FC = () => {

    return (
        <Layout>
            <Switch>
                <Route exact path="/:docToken?" component={DokumentModule} />
                <Route exact path="test" component={TestModule} />
            </Switch>
        </Layout>
    );
}

export default App;
