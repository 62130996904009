import { LanguageCodeEnum } from '../../enums/LanguageCodeEnum';

export default class DokumentyKlientaUrl {

	IdKlientskaZonaUrl?: number;
	IdZakaznik?: number;
	IdZastupceMatkaOsCislo?: number;
	IdZastupceMatka?: number;
	OsCislo: string = "";
	Url: string = "";
	Vygenerovano?: string;
	ObsahEmailu: string = "";
	PlatnostDo?: string;
	ZemeOz: LanguageCodeEnum = LanguageCodeEnum.CZ;

	public constructor(init?: Partial<DokumentyKlientaUrl>) {
		Object.assign(this, init);
	}
}