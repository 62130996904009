import { createMuiTheme, Theme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

const AppTheme: Theme = createMuiTheme({
	palette: {
		primary: {
			main: '#dc6b2f'
		}
	}
});

export default AppTheme;