import React, { useState } from 'react';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';

interface IProps {
    AreaText?: string;

    DialogText?: string;
    DialogTitle?: string;
    PridatButtonText?: string;
    SubmitButtonText?: string;
    CancelButtonText?: string;
    DialogOnSave: (files: any) => void;
    DialogOnClose: () => void;
    Open: boolean;
    ButtonOnClick: (e: any) => void;
}

const FileUpload: React.FC<IProps> = (props) => {

    return (
        <>
            <Button onClick={(e) => props.ButtonOnClick(e)} color="primary" variant="contained">
                {props.PridatButtonText}
            </Button>
            <DropzoneDialog
                fullWidth={true}
                open={props.Open}
                acceptedFiles={[]}
                showPreviews={false}
                cancelButtonText={props.CancelButtonText || "Zrušit"}
                submitButtonText={props.SubmitButtonText || "Uložit"}
                useChipsForPreview={true}
                showPreviewsInDropzone={true}
                onSave={props.DialogOnSave}
                onClose={props.DialogOnClose}
                dropzoneText={props.DialogText || "Zde vložte soubory"}
                dialogTitle={props.DialogTitle || "Nahrávání souborů"}
                showAlerts={false}
                filesLimit={10}
                maxFileSize={10000000}
            />
        </>
    );
}

export default FileUpload;