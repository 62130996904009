import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import AppAlertProps from '../../../models/helpers/AppAlertProps';

function Alert(props: AlertProps) {
    debugger;
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IProps {
    AppAlertProps: AppAlertProps;
    SetAppAlertProps: React.Dispatch<React.SetStateAction<AppAlertProps>>;
}

const AppAlert: React.FC<IProps> = props => {
    debugger;
    const classes = useStyles();        

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        debugger;
        if (reason === 'clickaway') {
            return;
        }

        props.SetAppAlertProps(new AppAlertProps({ Open: false }));
        //props.AppAlertProps.Open = false;
    };

    return (
        <div className={classes.root}>            
            <Snackbar open={props.AppAlertProps.Open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.AppAlertProps.Severity || "success"}>
                    {props.AppAlertProps.Message}
                </Alert>
            </Snackbar>           
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    })
);

export default AppAlert;