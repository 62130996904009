import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, DialogActions, TextField, Theme, createStyles } from '@material-ui/core';
import FileInfo from '../../../models/helpers/FileInfo';

export interface IProps {
    open: boolean;
    onClickCloseDialog: () => void;
    onClickZrusitDialogButton: () => void;
    onClickEditDialogButton: () => void;
    onClickSaveDialogButton: () => void;
    onChangePoznamkaDialog: (e: any, index: number) => void;
    fileInfo: Array<FileInfo>;
    dialogTitle: string;
    dialogContentText: string;
    dialogButtonZrusitText: string;
    dialogButtonUlozitText: string;
    dialogButtonPouzeUlozitText: string;
    dialogButtonAnoText: string;
}

const DokumentyPoznamkaDialog: React.FC<IProps> = props => {
    const classes = useStyles();
    const [zdaEdit, setZdaEdit] = useState<boolean>(false);

    const handleAnoOnClick = () => {
        setZdaEdit(true);
    }

    const handleZrusitOnClick = () => {
        props.onClickZrusitDialogButton();
        setZdaEdit(false);
    }

    const handleUlozit = () => {
        props.onClickSaveDialogButton()
        setZdaEdit(false);
    }

    return (
        <Dialog
            onClose={props.onClickCloseDialog}
            aria-labelledby="simple-dialog-title"
            open={props.open}>
            <DialogTitle id="simple-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent style={{ width: '500px' }}>
                {zdaEdit ? (
                    <>
                        {props.fileInfo.map((val, index) => {
                            return (
                                <div key={index} style={{ marginBottom: '20px' }}>
                                    <div>
                                        <TextField
                                            className={classes.textFieldMultiline}
                                            id={`poznamkaId_${index}`}
                                            label={`Poznámka k souboru: ${val.FileName}`}
                                            value={val.Poznamka}
                                            multiline={true}
                                            rows={2}
                                            onChange={(e) => props.onChangePoznamkaDialog(e, index)}
                                            inputProps={{ maxlength: 100 }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                        <DialogContentText id="alert-dialog-description">
                            {props.dialogContentText}
                        </DialogContentText>
                    )
                }
            </DialogContent>
            <DialogActions>
                {zdaEdit ?
                    (
                        <>
                            <Button onClick={handleZrusitOnClick} color="primary" >
                                {props.dialogButtonZrusitText}
                            </Button>
                            <Button onClick={handleUlozit} color="primary" variant="contained" autoFocus>
                                {props.dialogButtonUlozitText}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={props.onClickCloseDialog} color="primary" >
                                {props.dialogButtonPouzeUlozitText}
                             </Button>
                            <Button onClick={handleAnoOnClick} color="primary" variant="contained" autoFocus>
                                {props.dialogButtonAnoText}
                            </Button>
                        </>
                    )}

            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            width: '500px'
        },
        textFieldMultiline: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: '100%'
        },
    }),
);

export default DokumentyPoznamkaDialog;