import axios, { AxiosResponse } from 'axios';

import ZakaznikDokumenty from '../models/application/zakaznik/ZakaznikDokument';
import FileInfo from '../models/helpers/FileInfo';
import DokumentyKlientaUrl from '../models/application/dokumenty/DokumentyKlientaUrl';

export default class DokumentRepository {
	private url: string;

	constructor() {
		this.url = process.env.REACT_APP_API_URL || "";
	}

	public async upload(pToken: string, pFiles: any, pFileInfo: Array<FileInfo>) {
		let data = new FormData();
		for (var i = 0; i < pFiles.length; i++) {
			data.append("file" + i, pFiles[i]);
		}
		data.append(`FileInfoList`, JSON.stringify(pFileInfo));
		
		data.append("token", pToken);

		return await new Promise<boolean>((resolve) => {			
			axios.post(`${this.url}/dokumenty/upload`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then((response: AxiosResponse<boolean>) => {
					resolve(response.data);
				})
		});
	}

	public async delete(pToken: string, pIdDocEntityLink: number) {
		return await new Promise<boolean>((resolve) => {
			axios.post(`${this.url}/dokumenty/delete?pToken=${pToken}&pIdDocEntityLink=${pIdDocEntityLink}`)
				.then((response: AxiosResponse<boolean>) => {
					resolve(response.data);
				})
		});
	}

	public async overeniPlatnostiUrl(pToken: string) {
		const url = `${this.url}/dokumenty/overeniplatnosti?pToken=${pToken}`;

		return await new Promise<boolean>((resolve) => {
			axios.get(url)
				.then((response: AxiosResponse<boolean>) => {
					resolve(response.data);
				})
				.catch(error => {
					// log error
				})
		})
	}

	public async getDokumentyZakanzika(pToken: string) {
		const url = `${this.url}/dokumenty/getdokumentyzakanzika?pToken=${pToken}`;		

		return await new Promise<Array<ZakaznikDokumenty>>((resolve) => {
			axios.get(url)
				.then((response: AxiosResponse<Array<ZakaznikDokumenty>>) => {
					resolve(response.data);
				})
				.catch(error => {
					// log error
				})
		})
	}

	public async getUrlInfo(pToken: string) {
		const url = `${this.url}/dokumenty/GetUrlInfo?pToken=${pToken}`;		

		return await new Promise<DokumentyKlientaUrl>((resolve) => {
			axios.get(url)
				.then((response: AxiosResponse<DokumentyKlientaUrl>) => {
					resolve(response.data);
				})
				.catch(error => {
					// log error
				})
		})
	}
}